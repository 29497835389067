<template>
  <div class="container" :style="{ backgroundImage: 'url(' + require('@/assets/image/bg-01.jpg') + ')' }">
    <div style="position: absolute;top: 15%;right: 15%">
      <div class="login-png"><img style="width: 160px;" src="../assets/image/login.png"></div>
      <div class="login-form">
        <div style="display: flex;justify-content: center;">
          <div @click="choiceLoginType(true)" style="width: 50%"
               :style="thisLoginType == true ? 'background-image: linear-gradient(#e2e2e2 50%, white 50%);' : 'background-image: linear-gradient(#e2e2e2 50%,white 50%);color: #8e8d8d;'">
            <div style="padding-top: 20px;"
                 :style="thisLoginType == true ? 'background-color: white;border-radius: 0 20px 0 0;' : 'background-color: #e2e2e2;border-radius: 0 0 20px 0;'">
              <h4 class="go">短信登录</h4>
            </div>
          </div>
          <div @click="choiceLoginType(false)" style="width: 50%;"
               :style="thisLoginType == false ? 'background-image: linear-gradient(#e2e2e2 50%, white 50%);' : 'background-image: linear-gradient(#e2e2e2 50%,white 50%);color: #8e8d8d;'">
            <div style="padding-top: 20px;"
                 :style="thisLoginType == false ? 'background-color: white;border-radius:20px  0 0 20px' : 'background-color: #e2e2e2;border-radius: 0 0 0 20px'">
              <h4 class="go">账户登录</h4>
            </div>
          </div>
        </div>
        <div v-if="thisLoginType == true" style="margin: 40px 50px;">
          <div style="margin: 20px 0" class="wrap-input100 validate-input m-b-23" data-validate="请输入用户名">
            <span style="margin-top: 10px" class="label-input100">手机号</span>
            <div style="display: flex;align-items:center;">
              <div class="el-icon-mobile-phone" style="color: #8e8d8d;"></div>
              <input maxlength="11" v-model="authCodeLogin.phone" class="input100" name="pass" placeholder="请输入手机号">
              <span class="focus-input100"></span>
            </div>
          </div>
          <div class="wrap-input100 validate-input" data-validate="请输入验证码">
            <span class="label-input100">验证码</span>
            <div style="display: flex;align-items:center;">
              <div class="el-icon-lock" style="color: #8e8d8d;"></div>
              <input @keyup.enter="loginByAuthCode()" maxlength="6" v-model="authCodeLogin.code" class="input100"
                     name="pass" placeholder="请输入验证码">
              <span class="focus-input100"></span>
              <el-button :disabled="authCode != '获取验证码'" @click="getAuthCode()"
                         style="border-radius: 10px;padding:12px 14px"
                         size="small">
                {{ authCode == '获取验证码' ? authCode : authCode + ' 秒' }}
              </el-button>
            </div>

          </div>
          <div style="padding-top: 40px;text-align: center">
            <el-button style="margin: 0 auto;width: 200px;padding: 14px 0px" :loading="isLogin" :disabled="isLogin"
                       @click="loginByAuthCode()" round>{{ isLogin == true ? '请稍等...' : '登 录' }}
            </el-button>
          </div>
        </div>
        <div v-else style="margin: 40px 50px;">
          <div style="margin: 20px 0" class="wrap-input100 validate-input m-b-23" data-validate="请输入用户名">
            <span style="margin-top: 10px" class="label-input100">账户</span>
            <div style="display: flex;align-items:center;">
              <div class="el-icon-user" style="color: #8e8d8d;"></div>
              <input v-model="param.username" class="input100" name="pass" placeholder="请输入密码">
              <span class="focus-input100"></span>
            </div>
          </div>
          <div class="wrap-input100 validate-input" data-validate="请输入密码">
            <span class="label-input100">密码</span>
            <div style="display: flex;align-items:center;">
              <div class="el-icon-lock" style="color: #8e8d8d;"></div>
              <input @keyup.enter="loginByAccount()" v-model="param.password" class="input100" type="password"
                     name="pass" placeholder="请输入密码">
              <span class="focus-input100"></span>
            </div>

          </div>
          <div style="padding-top: 40px;text-align: center">
            <el-button style="margin: 0 auto;width: 200px;padding: 14px 0px" :loading="isLogin" :disabled="isLogin"
                       @click="loginByAccount()" round>{{ isLogin == true ? '请稍等...' : '登 录' }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAuthCode, login, loginByAuthCode} from '../api/login'

export default {
  name: "Login",
  data() {
    return {
      thisLoginType: false,
      authCode: "获取验证码",
      isLogin: false,
      authCodeLogin: {
        phone: "",
        code: ""
      },
      param: {
        username: "",
        password: ""
      },
    };
  },
  methods: {
    /**
     * 选择登陆方式
     */
    choiceLoginType(type) {
      this.thisLoginType = type;
    },
    /**
     * 获取验证码
     */
    getAuthCode() {
      let phone = this.authCodeLogin.phone;
      if (phone.length == 11 && /^[0-9]+.?[0-9]*/.test(phone)) {
        this.authCode = 60;
        getAuthCode(phone).then(res => {
          if (res.code == 200) {
            this.$message.success("已发送！");
          } else if (res.code == 500) {
            this.$message.warning(res.message);
          }
        })
      } else {
        this.$message.warning('手机号有误！');
      }
    },
    /**
     * 验证码登陆
     */
    loginByAuthCode() {
      let phone = this.authCodeLogin.phone;
      let code = this.authCodeLogin.code;
      if (phone.length != 11 && !/^[0-9]+.?[0-9]*/.test(phone)) {
        this.$message.warning('手机号有误！');
        return;
      }
      if (code.length != 6 && !/^[0-9]+.?[0-9]*/.test(code)) {
        this.$message.warning('验证码有误！');
        return;
      }
      this.isLogin = true;
      loginByAuthCode(this.authCodeLogin).then(res => {
        this.isLogin = false;
        if (res.code == 200) {
          this.$message.success("登陆成功！");
        } else if (res.code === 500) {
          this.$message.warning(res.message);
        }
      })
    },
    /**
     * 用户名密码登陆
     */
    loginByAccount() {
      if (this.param.username === "") {
        this.$message.warning('请输入账户！');
        return;
      }
      if (this.param.password === "") {
        this.$message.warning('请输入密码！');
        return;
      }
      this.isLogin = true;
      this.param.userPhone = this.param.username;
      login(this.param).then(res => {
        this.isLogin = false;
        if (res.status === 200 && res.data.code == 200) {
          let user = JSON.stringify(res.data.data);
          localStorage.setItem("user",user);
           this.$message.success("登录成功!");
          this.$router.replace('/workspace');
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    getTypeList(commodityType) {
      commodityType.forEach(items => {
        if (items != null) {
          //isButton是0 则不是按钮 不设置当前xx页面thisPage的permission参数
          if (items.children != null && items.children.length > 0 && items.children[0].isButton === 0) {
            this.getTypeList(items.children);
          } else {
            //isButton是1 设置当前xx页面thisPage的permission参数
            if (items.children != null && items.children.length > 0 && items.children[0].isButton === 1) {
              let myPermission = [];
              items.children.forEach(item => {
                myPermission.push(item.index);
              });
              //设置多级的当前xx页面thisPage的permission参数
              items.permission = myPermission;
            }
            items.children = null;
          }
        }
      });
      return commodityType;
    },
    //解析多节Menu菜单页面 权限 按钮
    getTypeList2(commodityType) {
      commodityType.forEach(items => {
        if (items != null) {
          if (items.children != null && items.children.length > 0) {
            this.getTypeList2(items.children);
          } else {
            items.children = null;
          }
        }
      });
      return commodityType;
    },
  },
  watch: {
    authCode(value) {
      if (value == 0) {
        this.authCode = "获取验证码";
        return;
      }
      if (value != "获取验证码") {
        setTimeout(() => {
          this.authCode--;
        }, 1000);
      }
    },
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.login-png{
  display:flex;
  justify-content:center;
  background-color: white;
  border-radius: 20px;
  padding: 16px 0;
  width: 240px;
  margin:20px auto;
  background-color: white
}
.login-form {
  width: 380px;
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
}


a {
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #666666;
}

/*---------------------------------------------*/
p {
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

.get-password {
  font-size: 12px;
  width: 120px;
  border: #a7a7a7 1px solid;
  color: #a7a7a7;
  padding: 6px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input:-moz-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}

textarea:-moz-placeholder {
  color: #adadad;
}

textarea::-moz-placeholder {
  color: #adadad;
}

textarea:-ms-input-placeholder {
  color: #adadad;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
}

.txt2 {
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
  text-transform: uppercase;
}

.bg1 {
  background-color: #3b5998
}

.bg2 {
  background-color: #1da1f2
}

.bg3 {
  background-color: #ea4335
}


.go {
  padding-bottom: 20px;
  text-align: center;
}



/*------------------------------------------------------------------
[ Form ]*/


.login100-form-title {
  display: block;
  font-family: 'Microsoft Yahei';
  font-size: 39px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}


/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
}

.label-input100 {
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  color: #333333;
  line-height: 1.5;
}

.input100 {
  font-family: 'Microsoft Yahei';
  font-size: 12px;
  color: #333333;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 50px;
  background: transparent;
  padding: 0 7px 0 20px;
}


/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::after {
  content: attr(data-symbol);
  font-family: Material-Design-Iconic-Font;
  color: #adadad;
  font-size: 22px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(100% - 20px);
  bottom: 0;
  left: 0;
  padding-left: 13px;
  padding-top: 3px;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: #7f7f7f;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}


.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.input100:focus + .focus-input100::after {
  color: #666666;
}

.has-val.input100 + .focus-input100::after {
  color: #666666;
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  bottom: calc((100% - 20px) / 2);
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  right: 2px;
  pointer-events: none;

  font-family: 'Microsoft Yahei';
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  bottom: calc((100% - 20px) / 2);
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  right: 8px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}


.login100-social-item {
  font-size: 25px;
  color: #fff;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 5px;
}

.login100-social-item:hover {
  color: #fff;
  background-color: #333333;
}
</style>
