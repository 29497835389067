import request from '@/api/request.js'

export function login(param) {
    return request({
        url: 'sys/auth/getLoginToken',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        method: 'POST',
        data: param,
    })
}